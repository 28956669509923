import {
  Button, Col, Image, Row,
} from 'react-bootstrap';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import CoverWaltty from '../../images/products/the-waltty-deck/cover.jpg';
import Intro01 from '../../images/products/the-waltty-deck/intro_01.jpg';
import Intro02 from '../../images/products/the-waltty-deck/intro_02.jpg';
import Intro03 from '../../images/products/the-waltty-deck/intro_03.jpg';
import Intro04 from '../../images/products/the-waltty-deck/intro_04.jpg';
import Intro05 from '../../images/products/the-waltty-deck/intro_05.jpg';
import Intro06 from '../../images/products/the-waltty-deck/intro_06.jpg';
import Intro07 from '../../images/products/the-waltty-deck/intro_07.jpg';
import Intro08 from '../../images/products/the-waltty-deck/intro_08.jpg';
import Intro09 from '../../images/products/the-waltty-deck/intro_09.jpg';
import Intro10 from '../../images/products/the-waltty-deck/intro_10.jpg';
import Intro11 from '../../images/products/the-waltty-deck/intro_11.jpg';
import * as styles from './Products.module.scss';

function TheWalttyDeck() {
  return (
    <Layout>
      <SEO title="The Waltty Deck" />
      <Row className="bg-primary g-0">
        <Col>
          <Row className="container mx-auto py-5">
            <Col>
              <Row>
                <Col xs={12} md className="">
                  <Image
                    src={CoverWaltty}
                    alt="The Waltty Deck"
                    className={`w-100 ${styles.imageItem}`}
                  />
                </Col>
                <Col xs={12} md>
                  <h1>The Waltty Deck</h1>
                  <h3>$10.00</h3>
                  <p className={`font-weight-light ${styles.leading}`}>
                    The Waltty Deck was released in June 2016 which designed and
                    manufactured by Waltty form Taiwan. Waltty believe that play
                    and collect playing cards is an important thing in life. Enjoy
                    the fun with it is a unique lifestyle. Waltty implement their
                    own stories and ideas on the playing card design to show the
                    purely PASSION. Furthermore, The Waltty Deck is a NEW CHOICE
                    to the people who like playing cards.
                  </p>
                  <Button variant="secondary" disabled>
                    SOLD OUT
                  </Button>
                  {' '}
                  or buy from
                  {' '}
                  <a
                    className="text-secondary"
                    href="https://bombmagic.tw/store/product/waltty/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    supplier
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="bg-dark g-0">
        <Col>
          <Row className="container mx-auto py-5">
            <Col>
              <Row className="mb-4">
                <Col xs={12} md>
                  <h2>About The Waltty Deck</h2>
                  <p className={`font-weight-light ${styles.leading}`}>
                    The Waltty Deck was released in June 2016 which designed and
                    manufactured by Waltty from Taiwan. We believe that play and
                    collect playing cards is an important thing in life. Enjoy the
                    fun with it is a unique lifestyle. We implement our stories
                    and ideas on the playing card design to show the purely
                    passion.
                  </p>
                </Col>
                <Col xs={12} md className="text-center">
                  <Image
                    src={Intro01}
                    alt="Back design"
                    className={`w-100 ${styles.imageItem}`}
                  />
                  <p>Back design</p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md className="text-center">
                  <Image
                    src={Intro02}
                    alt="Tuck box and back"
                    className={`w-100 ${styles.imageItem}`}
                  />
                  <p>Tuck box and back</p>
                </Col>
                <Col xs={12} md>
                  <p className={`font-weight-light ${styles.leading}`}>
                    The main concept of The Waltty Deck can describe in one
                    sentence ─ “Life is about eat, sleep and get The Waltty Deck.”
                    We want to make a deck which we can’t stop loving it. When we
                    seek the meaning of life, we can bring it together happily.
                    Furthermore, we hope it will be a new choice to the people who
                    like playing cards.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="sectionMedium g-0">
        <Col>
          <Row className="container mx-auto py-5">
            <Col>
              <Row className="mb-4">
                <Col xs={12} md>
                  <h2>Features</h2>
                  <p className={`font-weight-light ${styles.leading}`}>
                    The design style of The Waltty Deck is simple and fresh.
                    Overall, it consists of red color and lines with the different
                    length and thickness. There are many details on The Waltty
                    Deck. On the tuck box, you can find a number 25. The origin of
                    the number is mean a negative #25 in the movie “The Secret
                    Life of Walter Mitty (2013).” We use the number 25 to
                    represent the process of we create the deck.
                  </p>
                </Col>
                <Col xs={12} md className="text-center">
                  <Image
                    src={Intro03}
                    alt="AWEPLAN"
                    className={`w-100 ${styles.imageItem}`}
                  />
                  <p>AWEPLAN</p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md className="text-center">
                  <Image
                    src={Intro04}
                    alt="Aces"
                    className={`w-100 ${styles.imageItem}`}
                  />
                  <p>Aces</p>
                </Col>
                <Col xs={12} md>
                  <p className={`font-weight-light ${styles.leading}`}>
                    The word “AWEPLAN” is blend of awesome and plan that mean we
                    do our best for a really cool thing. And the new design of the
                    aces is particularly interesting. They are designed in lines
                    to show the four playing cards suits abstractly.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="bg-dark g-0">
        <Col>
          <Row className="container mx-auto py-5">
            <Col>
              <Row className="mb-4">
                <Col xs={12} md>
                  <h2>Information</h2>
                  <p className={`font-weight-light ${styles.leading}`}>
                    We printed 1,000 decks of The Waltty Deck and only 450 decks
                    available for sale.
                  </p>
                  <Button variant="secondary" disabled>
                    SOLD OUT
                  </Button>
                  {' '}
                  or buy from
                  {' '}
                  <a
                    className="text-secondary"
                    href="https://bombmagic.tw/store/product/waltty/"
                    role="button"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    supplier
                  </a>
                </Col>
                <Col xs={12} md className="text-center">
                  <Image
                    src={Intro05}
                    alt="The Waltty Deck"
                    className={`w-100 ${styles.imageItem}`}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4} className="text-center mb-4">
                  <Image
                    src={Intro06}
                    alt="The Waltty Deck"
                    className={`w-100 ${styles.imageMiniItem}`}
                  />
                </Col>
                <Col xs={12} md={4} className="text-center mb-4">
                  <Image
                    src={Intro07}
                    alt="The Waltty Deck"
                    className={`w-100 ${styles.imageMiniItem}`}
                  />
                </Col>
                <Col xs={12} md={4} className="text-center mb-4">
                  <Image
                    src={Intro08}
                    alt="The Waltty Deck"
                    className={`w-100 ${styles.imageMiniItem}`}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4} className="text-center mb-4">
                  <Image
                    src={Intro09}
                    alt="The Waltty Deck"
                    className={`w-100 ${styles.imageMiniItem}`}
                  />
                </Col>
                <Col xs={12} md={4} className="text-center mb-4">
                  <Image
                    src={Intro10}
                    alt="The Waltty Deck"
                    className={`w-100 ${styles.imageMiniItem}`}
                  />
                </Col>
                <Col xs={12} md={4} className="text-center mb-4">
                  <Image
                    src={Intro11}
                    alt="The Waltty Deck"
                    className={`w-100 ${styles.imageMiniItem}`}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
}

export default TheWalttyDeck;
